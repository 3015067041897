:root {
	/* ------- colors ------- */
	--primary-color: #000000;
	--secondary-color: #333333;
	--tertiary-color: #444444;
	--quaternary-color: #555555;
	--link-color: #492211;
	/* ---------------------- */

	/* ------- fonts ------- */
	--primary-font: "Nunito", sans-serif;
	--secondary-font: "Nunito", sans-serif;
	/* --------------------- */
}
